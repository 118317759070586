import { mapState } from "vuex";
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import { POST, PATCH } from "@/core/services/store/request.module";
import {
  GET_COUNTRY,
  GET_STATE,
  GET_CITY,
} from "@/core/services/store/common.module";
import LocalService from "@/core/services/local.service";
import accounting from "accounting-js";
import ObjectPath from "object-path";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data() {
    return {
      offset_top: 0,
      listing_height: 0,
      device_width: document.documentElement.clientWidth,
      device_height: document.documentElement.clientHeight,
      hasChanges: true,
      forcePush: false,
      deleteValid: true,
      deleteLoading: false,
      routePreventDialog: false,
      deleteValidateText: null,
      perfectScrollHeight: 720,
      formValid: true,
      formLoading: false,
      dialog: false,
      commonDialog: false,
      datePicker: false,
      pageProcessing: false,
      countryList: [],
      stateList: [],
      cityList: [],
      bulkRows: [],
      CurrencySymbol: null,
      currentActiveField: "product_type",
      errorDialog: false,
      mapCenter: { lat: 1.29027, lng: 103.851959 },
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
      engineerDesignations: [
        { title: "Senior", value: 1 },
        { title: "Junior", value: 2 },
      ],
      // prettier-ignore
      // eslint-disable-next-line max-len
      allowedDocumentMime: "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/vnd.rar, application/vnd.ms-powerpoint, application/zip, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/csv, application/vnd.ms-excel, image/png, image/jpeg, image/bmp",
      discountTypeList: [
        { text: "At transaction level", value: "entity_level" },
        { text: "At line item level", value: "item_level" },
        { text: "No Discount", value: "no_discount" },
      ],
      currentPhoneMask: "+65-####-####",
      accountingList: [
        { text: "FIFO", value: "fifo" },
        { text: "FEFO", value: "fefo" },
        { text: "NONE", value: "none" },
      ],
      salutationList: [
        { text: "Mr.", value: "mr" },
        { text: "Mrs.", value: "mrs" },
        { text: "Ms.", value: "ms" },
        { text: "Miss.", value: "miss" },
        { text: "Dr.", value: "dr" },
      ],
      emailTypeList: [
        { text: "Main", value: "main" },
        { text: "Work", value: "work" },
        { text: "Home", value: "home" },
      ],
      contactTypeList: [
        { text: "Main", value: "main" },
        { text: "Work", value: "work" },
        { text: "Mobile", value: "mobile" },
        { text: "Home", value: "home" },
        { text: "Fax", value: "fax" },
        { text: "Other", value: "other" },
      ],
      personBulkActions: [
        { title: "Mark as Active", key: "persons", action: "active" },
        { title: "Mark as Inactive", key: "persons", action: "inactive" },
        { title: "Mark as Default", key: "persons", action: "default" },
      ],
      propertyBulkActions: [
        { title: "Mark as Active", key: "property", action: "active" },
        { title: "Mark as Inactive", key: "property", action: "inactive" },
      ],
      imageDropzoneOptions: {
        paramName: "image",
        maxFiles: 5,
        dropzoneOptions: false,
        url: this.$apiURL + "file-manager/images",
        parallelUploads: 5,
        acceptedFiles: "image/png, image/jpeg",
        uploadMultiple: true,
        maxfilesexceeded: function (file) {
          this.removeAllFiles();
          this.addFile(file);
        },
      },
    };
  },
  methods: {
    is_number: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        const value = evt.target.value;
        if (value) {
          const value_array = value.split(".");
          const decimal = ObjectPath.get(value_array, "1");
          if (decimal && decimal.length == 2) {
            evt.preventDefault();
          }
        }
        return true;
      }
    },
    validateForm(formRef) {
      const _this = this;
      const formErrors = [];
      const { inputs } = formRef;
      for (let i = 0; i < inputs.length; i++) {
        const { errorBucket } = inputs[i];
        for (let z = 0; z < errorBucket.length; z++) {
          const errorMessage = _this.lodash.capitalize(
            _this.lodash.toLower(errorBucket[z])
          );
          formErrors.push(errorMessage);
        }
      }
      return formErrors;
    },
    dateTimeHumanize(startDateTime) {
      return moment(startDateTime).fromNow();
    },
    isNotEmpty(param) {
      return this.lodash.isEmpty(param) === false;
    },
    formatGPSAddress(address) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(POST, { url: "create-address-from-google", data: address })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            _this.logError(error);
            reject(error);
          });
      });
    },
    updateActiveField(param) {
      this.currentActiveField = param;
    },
    getCountries(param) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let country = _this.lodash.toInteger(param);
          let countryArr = LocalService.getData("country-" + country);
          if (_this.lodash.isEmpty(countryArr) === true) {
            _this.$store
              .dispatch(GET_COUNTRY, country)
              .then((response) => {
                LocalService.saveData("country-" + country, response.data);
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(countryArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getStates(param1, param2) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let country = _this.lodash.toInteger(param1.id);
          let state = _this.lodash.toInteger(param2);
          let stateArr = LocalService.getData("state-" + country + "-" + state);
          if (_this.lodash.isEmpty(stateArr) === true && country > 0) {
            _this.$store
              .dispatch(GET_STATE, country + "/" + state)
              .then((response) => {
                LocalService.saveData(
                  "state-" + country + "-" + state,
                  response.data
                );
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(stateArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCities(param1, param2) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let state = _this.lodash.toInteger(param1.id);
          let city = _this.lodash.toInteger(param2);
          let cityArr = LocalService.getData("city-" + state + "-" + city);
          if (_this.lodash.isEmpty(cityArr) === true && state > 0) {
            _this.$store
              .dispatch(GET_CITY, state + "/" + city)
              .then((response) => {
                LocalService.saveData(
                  "city-" + state + "-" + city,
                  response.data
                );
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(cityArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    bulkAction(param) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.bulkRows[param.key]) === false) {
        switch (param.action) {
          case "active":
            _this.bulkUpdate(param.url, {
              status: true,
              items: _this.bulkRows[param.key],
            });
            break;
          case "inactive":
            _this.bulkUpdate(param.url, {
              status: false,
              items: _this.bulkRows[param.key],
            });
            break;
          case "default":
            _this.bulkUpdate(param.url, {
              default: true,
              items: _this.bulkRows[param.key],
            });
            break;
          case "delete":
            _this.logError(param);
            break;
        }
      }
    },
    bulkUpdate(route, requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, { url: route, data: requestObject })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.bulkRows = [];
        });
    },
    /*
    bulkDelete(route, requestObject){
      const _this = this;
      _this.$store.dispatch(DELETE,{ url: route, data: requestObject }).then(() => {
      }).catch(() => {
      }).finally(() => {
        _this.selectedRows = [];
        _this.getRows();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      });
    },*/
    getShortName: function (string) {
      if (string) {
        let stringArr = string.split(" ");
        let result = new Array();
        if (stringArr[0]) {
          result.push(stringArr[0].charAt(0));
        }
        if (stringArr[1]) {
          result.push(stringArr[1].charAt(0));
        }
        return result.join("");
      }
      return "-";
    },
    formatDate: function (date) {
      if (!date) {
        return "-";
      }
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(date).format(Config.dateFormat);
      }
      return date;
    },
    formatTime: function (time) {
      if (!time) {
        return "-";
      }
      let Config = AppConfiguration.get();
      if (Config) {
        if (time.length <= 6) {
          return moment(
            new Date().toISOString().substr(0, 10) + " " + time
          ).format(Config.timeFormat);
        }
        return moment(time).format(Config.timeFormat);
      }
      return time;
    },
    formatDateTime: function (date) {
      if (!date) {
        return "-";
      }
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(date).format(Config.dateTimeFormat);
      }
      return date;
    },
    formatNumber: function (number) {
      return accounting.formatNumber(number);
    },
    formatMoney: function (number) {
      return accounting.formatMoney(number, this.CurrencySymbol);
    },
    accountingUnFormat: function (number) {
      return accounting.unformat(number);
    },
    accountingToFixed: function (number) {
      return accounting.toFixed(number);
    },
    booleanToString(param) {
      switch (param) {
        case true:
          return "Yes";
        case false:
          return "No";
        default:
          "No";
      }
    },
    commitErrors(response) {
      let requestErrors = new Array();
      if (response && response.status === 422) {
        for (let error in response.data) {
          if (response.data[error]) {
            for (let i = response.data[error].length - 1; i >= 0; i--) {
              requestErrors.push(response.data[error][i]);
            }
          }
        }
      } else {
        requestErrors.push(response.toString());
      }
      return requestErrors;
    },
    getOffsetTop() {
      this.$nextTick(() => {
        //const body = document.querySelector("body");
        const _offset = document.querySelector(".scroll.ps");
        const header_height = document.querySelector("#kt_header");
        //const mobile_header_height = document.querySelector("#kt_header_mobile").clientHeight;
        this.offset_top = _offset.offsetTop + header_height.clientHeight;
        // this.listing_height = document.querySelector(".main-listing-page .v-data-table__wrapper").getBoundingClientRect().top + 46;
        // if(window.innerWidth < 992){
        //   this.offset_top = _offset + mobile_header_height;
        //   this.listing_height = document.querySelector(".main-listing-page .v-data-table__wrapper").getBoundingClientRect().top + 56;
        // }
      });
      // return document.querySelector('.scroll.ps').offsetTop;
    },
    getDimensions() {
      this.device_width = document.documentElement.clientWidth;
      this.device_height = document.documentElement.clientHeight;
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.request.errors,
    }),
    formattedDate() {
      if (this.lodash.isEmpty(this.dates) === false) {
        let Config = AppConfiguration.get();
        if (Config) {
          let dateArray = [];
          if (this.dates[0]) {
            dateArray.push(moment(this.dates[0]).format(Config.dateFormat));
          }
          if (this.dates[1]) {
            dateArray.push(moment(this.dates[1]).format(Config.dateFormat));
          }
          return dateArray.join(" ~ ");
        }
      }
      return null;
    },
    getDefaultImage() {
      return this.$filePath + "storage/uploads/images/no-image.png";
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    const divElement = document.querySelector("#content-body");
    if (divElement) {
      this.perfectScrollHeight = divElement.offsetHeight;
    }
    this.getOffsetTop();
  },
  beforeDestroy() {
    this.hasChanges = false;
    window.removeEventListener("resize", this.getDimensions);
  },
  created() {
    let Config = AppConfiguration.get();
    if (Config) {
      this.currentPhoneMask = Config.phoneMask;
      this.CurrencySymbol = Config.currency.symbol;
    }
  },
};
